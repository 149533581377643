import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import CaseStudy from "../../page-components/case-study";
import data, {
  relatedSuccessStories,
} from "../../data/case-studies/durham-bulls";
import RelatedResources from "../../components-v2/RelatedResources";

const DurhamBulls = () => {
  return (
    <Layout className="visible-overflow">
      <SEO title={data.pageTitle} description={data.pageDescription} />

      <CaseStudy className="mt-40 mb-40" {...data} />

      {/* <RelatedResources */}
      {/*  className="pt-28 lg:pt-32 pb-32 lg:pb-40" */}
      {/*  relatedResources={relatedSuccessStories} */}
      {/* /> */}
    </Layout>
  );
};

export default DurhamBulls;
