import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Durham Bulls",
  pageDescription:
    "Discover how a minor league baseball park provides quick food service to thousands of fans so they can keep their attention on the game.",
  title: "A POS system that knocks it out of the park",
  subTitle: `How the Durham Bulls expedite food orders with SpotOn.`,
  businessName: "Durham Bulls",
  industry: "Enterprise, Sports & Entertainment",
  location: "Durham, North Carolina",
  products: [
    spotonProducts.ENTERPRISE_POS,
    spotonProducts.ONLINE_ORDERING,
    spotonProducts.SELF_SERVICE_KIOSKS,
  ],
  description:
    "The Durham Bulls—a minor league baseball team in North Carolina—draw crowds up to 12,000 people at the Durham Bulls Athletic Park. And with SpotOn technology, the park has increased revenue while the fans spend less time in lines and more time watching the game. Win-win.",
  quoteData: {
    imgName: "durham-bulls.png",
    quote: `“We were very impressed with where SpotOn was going and always felt that this would be a solution we would want to look at.”`,
    personName: "Dave Levey",
    personTitle: "Director of Food & Beverage, Bull City Hospitality",
  },
  goal: `After hitting a paid attendance record in June 2019, the Durham Bulls knew they needed to expedite ordering so fans wouldn't have to wait in long lines. They also were looking for ways to boost sales with suggestive upselling supported by intuitive reporting data.`,
  solution: `SpotOn provided the Durham Bulls with self-service kiosks and mobile & online ordering so fans could order quickly at the counter or from their seats. And with helpful pairing suggestions and combo baskets, they saw a dramatic uptick in sales right after installation.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `9%`, description: `increase in total dollars spent` },
      {
        title: `20%`,
        description: `predicted revenue increase over two seasons`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Helping fans see more touchdowns",
    imageName: "louisiana-state-university.png",
    industry: resourceIndustries.ENTERPRISE,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-10",
    href: "/case-studies/louisiana-state-university",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
  {
    title: "Helping fans see more touchdowns",
    imageName: "louisiana-state-university.png",
    industry: resourceIndustries.ENTERPRISE,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-30",
    href: "/case-studies/louisiana-state-university",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
  {
    title: "Helping fans see more touchdowns",
    imageName: "louisiana-state-university.png",
    industry: resourceIndustries.ENTERPRISE,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-30",
    href: "/case-studies/louisiana-state-university",
    goals: [
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.STREAMLINE_OPERATIONS,
    ],
  },
];
